import React from "react"
import SEO from "../../components/seo"
import FullpageSectionCampioniDaBar from "../../components/fullpageSectionCampioniDaBar";
import FlexTextImageSectionCampioniSlider from "../../components/flexTextImageSectionCampioniSlider";
import FlexTextImageSectionCampioniAlbum from "../../components/flexTextImageSectionCampioniAlbum";
import WallBlackBackground from "../../components/backgroundImages/wallBlackBackground";
import WallYellowBackground from "../../components/backgroundImages/wallYellowBackground";
import PaintWall from "../../components/backgroundImages/paintWall";

import Paint from "../../images/paint.png"

const CampioniDaBarPage = () => {

  return (
    <main>
      <SEO
        title="Campioni da Bar"
        description=""
      />

      <div className="wall__background-wrapper wall__background--overlap-bottom">

        <WallBlackBackground />

        <PaintWall position={'top-right'} image={Paint} />

        <FullpageSectionCampioniDaBar/>

        <FlexTextImageSectionCampioniSlider />

      </div>

      <div className="wall__background-wrapper wall__background--overlap-top">

        <WallYellowBackground />

        <FlexTextImageSectionCampioniAlbum />

      </div>
    </main>
  );

};

export default CampioniDaBarPage;
