import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'

import "./wall-background.scss";

const WallBlackBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "wall-black-background.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid

  return (
    <div className="wall__background">
      <BackgroundImage
        Tag="div"
        fluid={imageData}
        backgroundColor={`#252525`}
        role="img"
        preserveStackingContext={true}
      />
    </div>
  )
}

export default WallBlackBackground;
