import React from "react";
import Swiper from "react-id-swiper";
import BackgroundImage from 'gatsby-background-image'

import 'swiper/css/swiper.css';
import "./swiper-campioni-da-bar.scss";

import contents from "./swiperCampioniDaBar.yml";
import {graphql, useStaticQuery} from "gatsby";

import PaintWall from "../backgroundImages/paintWall";
import Paint2 from "../../images/paint-2.png"

const SwiperCampioniDaBar = () => {

  const {
    slides,
  } = contents;

  const params = {
    slidesPerView: 2.5,
    // spaceBetween: '-15%',
    spaceBetween: 32,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 500,
    watchOverflow: true,
    watchSlidesVisibility: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 3
      }
    }
  };

  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "campioni-da-bar/bottle-background_v02.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid

  return (
    <div className="swiper-campioni-da-bar">

      <PaintWall position={"bottom-center"} image={Paint2} />

      <div className="bottle__background">
        <BackgroundImage
          Tag="div"
          fluid={imageData}
          // backgroundColor={`#252525`}
          role="img"
          preserveStackingContext={true}
        />
      </div>
      <Swiper containerClass="swiper-container swiper-campioni-da-bar__container" {...params}>
        {slides.map((slide, i) => {
          return (
            <div key={i} className="swiper-slide swiper-campioni-da-bar__slide">
              <div className="swiper-campioni-da-bar__image">
                <img
                  className=""
                  alt={slide.image.alt}
                  src={slide.image.fullpath}
                />
              </div>
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

export default SwiperCampioniDaBar;
