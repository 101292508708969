import React, {useState, useEffect} from 'react';
import VisibilitySensor from "../visibilitySensor";
import { Parallax } from "react-scroll-parallax";
import ButtonLink from "../buttonLink"
import PropTypes from "prop-types";

import "./fullpage-section.scss";

function createMarkup(html) { return {__html: html}; }

const Markup = (props) => {
    const Tag = props.tag;
    return <Tag className = {props.class} dangerouslySetInnerHTML = {createMarkup(props.content)} />
};

const FullpageSection = (props) => {

  // when isMobile is true we disable the parallax effect
  const [isMobile, setMobile] = useState(false);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateMedia();
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="fullpage-section__container">
      <div className={`fullpage-section__wrapper ${props.mobileInvertOrder ? 'fullpage-section__wrapper--mobile-invert-order' : ''}`}>
        <VisibilitySensor>
          {({ isVisible }) => (
            <div className={`fullpage-section__background ${props.noBackground ? 'fullpage-section__background--hide' : ''} ${props.noGradient ? 'fullpage-section__background--no-gradient' : ''} ${isVisible ? 'fadeIn sensorEnter' : 'fadeIn'}`}>
              {props.normalBackground ?
                <div className={`simple-background ${props.mobileAlign}`} role="img" style={{backgroundImage: `url(${props.backgroundImage})` }}></div>
                :
                <>
                  {props.children}
                </>
              }
            </div>
          )}
        </VisibilitySensor>

        {props?.noBackground &&
          <div className="header-spacer"></div>
        }

        <Parallax
          disabled={isMobile}
          y={['-100px', '100px']}
        >
          <VisibilitySensor>
            {({ isVisible }) => (
              <div className={`fullpage-section__content ${props?.isWide ? 'fullpage-section__content--wide' : 'slideUp'} ${isVisible ? 'slideUp sensorEnter' : 'slideUp'}`}>
                <Markup
                  tag={props.whichTitle}
                  content={props.copyTitle}
                  class="title"
                />
                {props.haveSubtitle &&
                  <Markup tag="h3" content={props.copySubtitle} class="subtitle" />
                }
                <Markup tag="p" content={props.copyText.replace(/(?:\r\n|\r|\n)/g, '<br />')} class="text" />
                {props.haveCTA &&
                  <div className="cta-wrapper">
                    <ButtonLink
                      to={props.buttonLinkTo}
                      target={props.buttonLinkTarget}
                      label={props.buttonLinkLabel}
                      trackingCategory={props.buttonLinkTrackingCategory}
                      trackingLabel={props.buttonLinkLabel} // same as label
                    />
                  </div>
                }
              </div>
            )}
          </VisibilitySensor>
        </Parallax>
      </div>
    </div>
  )
}

FullpageSection.propTypes = {
  mobileInvertOrder: PropTypes.bool,
  isWide: PropTypes.bool,
  noGradient: PropTypes.bool,
  noBackground: PropTypes.bool,
  normalBackground: PropTypes.bool,
  mobileAlign: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.any,
  whichTitle: PropTypes.string,
  copyTitle: PropTypes.string,
  haveSubtitle: PropTypes.bool,
  copySubtitle: PropTypes.string,
  copyText: PropTypes.string,
  haveCTA: PropTypes.bool,
  buttonLinkTo: PropTypes.string,
  buttonLinkTarget: PropTypes.string,
  buttonLinkLabel: PropTypes.string,
  buttonLinkTrackingCategory: PropTypes.string
};

export default FullpageSection;
