import React from "react";
import PropTypes from "prop-types";

import "./paint-wall.scss";

// choose and import from these images in the parent component
// import Paint from "../../images/paint.png"
// import Paint2 from "../../images/paint-2.png"

const PaintWall = (props) => {

  const {
    position,
    image
  } = props;

  return (
    <img className={`paint-wall paint-wall--${position}`} src={image} alt=""/>
  )
}

PaintWall.propTypes = {
  position: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired
};

export default PaintWall;
