import React from 'react';
import FullpageSection from "../fullpageSection"
import BackgroundCampioniDaBar from "../../images/hero-images/campioni-da-bar_v02.png";
// import {resolveLinkToRoute} from "../../helpers";

import contents from "./fullpageSectionCampioniDaBar.yml";

const FullpageSectionCampioniDaBar = () => {

  const {
    titleTag,
    title,
    text
  } = contents;

  return (
    <FullpageSection
      mobileInvertOrder={true}
      whichTitle={titleTag}
      copyTitle={title}
      copyText={text}
      normalBackground={true}
      noGradient={true}
      mobileAlign={"align-right"}
      backgroundImage={BackgroundCampioniDaBar}
    />
  )
};

export default FullpageSectionCampioniDaBar;
