import React from 'react';
import FlexTextImageSection from "../flexTextImageSection"
import {resolveLinkToRoute} from "../../helpers";

import contents from "./flexTextImageSectionCampioniSlider.yml";

import SwiperCampioniDaBar from "../swiperCampioniDaBar/swiperCampioniDaBar";

const FlexTextImageSectionCampioniSlider = () => {

  const {
    titleTag,
    title,
    text,
    link
  } = contents;

  return (
    <FlexTextImageSection
      colorBlack={''}
      whichTitle={titleTag}
      copyTitle={title}
      copyText={text}
      haveCTA={!!(link && link.to && link.to !== '')}
      buttonLinkTo={link && link.to && resolveLinkToRoute(link.to)}
      buttonLinkTarget={link && link.target}
      buttonLinkLabel={link && link.label}
      buttonLinkTrackingCategory={link && link.trackingCategory}
    >
      <SwiperCampioniDaBar />
    </FlexTextImageSection>
  )
};

export default FlexTextImageSectionCampioniSlider;
